import React from 'react';

function Contact() {
  return (
    <div>
      <div class="main">

        <section class="hero-section ptb-100 gradient-overlay" style={{ background: "url('images/header-bg-5.jpg')no-repeat center center / cover" }}>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-md-8 col-lg-7">
                <div class="page-header-content text-white text-center pt-sm-5 pt-md-5 pt-lg-0">
                  <h1 class="text-white mb-0">Contact Us</h1>
                 
                </div>
              </div>
            </div>
          </div>
        </section>
        <br /><br />
        <section class="contact-us-promo pt-100">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="card single-promo-card single-promo-hover text-center shadow-sm">
                  <div class="card-body py-5">
                    <div class="pb-2">
                      <span class="ti-mobile icon-sm color-secondary"></span>
                    </div>
                    <div><h5 class="mb-0">Call Us</h5>
                      <p class="text-muted mb-0">+255 749 509 509</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="card single-promo-card single-promo-hover text-center shadow-sm">
                  <div class="card-body py-5">
                    <div class="pb-2">
                      <span class="ti-location-pin icon-sm color-secondary"></span>
                    </div>
                    <div><h5 class="mb-0">Visit Us</h5>
                      <p class="text-muted mb-0">Plot 1403/1, Baines Singh Avenue, 1st Floor, Address Building, Dar es Salaam, Tanzania</p></div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="card single-promo-card single-promo-hover text-center shadow-sm">
                  <div class="card-body py-5">
                    <div class="pb-2">
                      <span class="ti-email icon-sm color-secondary"></span>
                    </div>
                    <div><h5 class="mb-0">Mail Us</h5>
                      <p class="text-muted mb-0">Sales@intelcom.co.tz</p></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <br /><br />
        <section class="contact-us-section ptb-100 ">
          <div class="container">
            <div class="row justify-content-around">
              <div class="col-md-5">
                <div className="submit-form">
                  <h3 className='msg-h'>Drop a Message..</h3>
                  <div class="formbold-main-wrapper">

                    <div class="formbold-form-wrapper">
                      <form >
                        <div class="formbold-mb-5">
                          <label for="name" class="formbold-form-label"> Full Name </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Full Name"
                            class="formbold-form-input"
                          />
                        </div>

                        <div class="formbold-mb-5">
                          <label for="email" class="formbold-form-label"> Email Address </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Enter your email"
                            class="formbold-form-input"
                          />
                        </div>

                        <div class="formbold-mb-5">
                          <label for="subject" class="formbold-form-label"> Subject </label>
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            placeholder="Enter your subject"
                            class="formbold-form-input"
                          />
                        </div>

                        <div class="formbold-mb-5">
                          <label for="message" class="formbold-form-label"> Message </label>
                          <textarea
                            rows="6"
                            name="message"
                            id="message"
                            placeholder="Type your message"
                            class="formbold-form-input"
                          ></textarea>
                        </div>

                        <div>
                          <button class="formbold-btn">Submit</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class=""><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1981.1045159121288!2d39.27846168988819!3d-6.744339702038486!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4d88f7546ad9%3A0x3e581cbc16308781!2sAddress%20Building!5e0!3m2!1sen!2sin!4v1728161048833!5m2!1sen!2sin" width="600" height="450" style={{ border: "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <br/> <br/>
    </div>
  );
}

export default Contact;
