import React from 'react';
import "./index.scss"
function Footer() {
  return (
    <div>
      <footer class="footer-section">

        <div class="footer-top gradient-bg">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="row footer-top-wrap">
                  <div class="col-md-4 col-sm-6">
                    <div class="footer-nav-wrap text-white">
                      <h4 class="text-white">COMPANY</h4>
                      <ul class="nav flex-column">

                        <p>Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                          in the realm of Information and Communication Technologies (ICT) and
                          Telecommunication equipment and solutions.</p>
                      </ul>
                    </div>
                  </div>
              
                  <div class="col-md-3 col-sm-6">
                    <div class="footer-nav-wrap text-white">
                      <h4 class="text-white">OUR SERVICES</h4>
                      <ul class="nav flex-column">
                        <li class="nav-item">
                          <a class="nav-link" href="/resellers-of-IT-Hardware-and-Softwares">Resellers of IT Hardware and Softwares </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/cuber-security-solutions">Cuber security solutions </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/managed-services">Managed Services</a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link" href="/enterprise-resource-planning"> Enterprise Resource Planning (ERP)</a>
                        </li>
                       

                      </ul>

                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="row footer-top-wrap">
                      <div class="col-12">
                     
                        <div class="footer-nav-wrap text-white">
                          <h4 class="text-white">GET IN TOUCH</h4>
                          <ul class="get-in-touch-list">
                            <li class="d-flex align-items-center py-2"><span class="fas fa-map-marker-alt mr-2"></span> Plot 1403/1, Baines Singh Avenue, 1st Floor, 
                            Address Building, Dar es Salaam, Tanzania</li>
                            <li class="d-flex align-items-center py-2"><span class="fas fa-envelope mr-2"></span> Sales@intelcom.co.tz</li>
                            <li class="d-flex align-items-center py-2"><span class="fas fa-phone-alt mr-2"></span> +255 749 509 509&#x202C;</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="footer-bottom gray-light-bg py-2">
          <div class="container">
            <div class="row align-items-center justify-content-between">
              <div class="col-md-5 col-lg-5">
                <p class="copyright-text pb-0 mb-0">Copyrights © 2020. All
                  rights reserved by
                  <a href="#" target="_blank"> Intelcom Limited</a></p>
              </div>
              <div class="col-md-7 col-lg-6 d-none d-md-block d-lg-block">
                <div class="social-nav text-right">
                  <ul class="list-unstyled social-list mb-0">
                    <li class="list-inline-item tooltip-hover">
                      <a href="#" class="rounded"><span class="ti-facebook"></span></a>

                    </li>
                    <li class="list-inline-item tooltip-hover"><a href="#" class="rounded"><span class="ti-twitter"></span></a>

                    </li>
                    <li class="list-inline-item tooltip-hover"><a href="#" class="rounded"><span class="ti-linkedin"></span></a>

                    </li>
                    <li class="list-inline-item tooltip-hover"><a href="#" class="rounded"><span class="ti-dribbble"></span></a>

                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

      </footer>
    </div>
  );
}

export default Footer;