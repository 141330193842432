import React from 'react';

function Service() {
  return (
    <div>
      <h1>Service Page</h1>
      <p>These are the services we offer.</p>
    </div>
  );
}

export default Service;
