
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import PartnerSider from '../partnerSider';
const ClientsLogo = [
    "./images/partner-img/microsoft.jpg",
    "./images/partner-img/redhat.png",
    "./images/partner-img/hp.png",
    "./images/partner-img/nutanix.png",
    "./images/partner-img/ubuntu.png",
    "./images/partner-img/lenovo.png",
    "./images/partner-img/checkpoint.png",
    "./images/partner-img/huawei.jpg",
]
function ManagedServices() {
    return (
        <div className='HardwareandSoftwares'>
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/ict.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                        Managed services solutions
                    </h1>
                </div>
            </section>
            <br /> <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" >

                <Container >
                    <div class="row">
                        <div class="col-md-8">
                            <div className="intel-hardwere">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con">
                                            <p>
                                                Managed services solutions involve outsourcing the management and maintenance of IT systems to a third-party service provider. These services help businesses manage their infrastructure, ensuring optimal performance, security, and reliability. Managed service providers (MSPs) handle tasks such as network monitoring, server management, cybersecurity, and data backups, allowing companies to focus on their core business activities.</p>
                                        </div>
                                    </div>
                                    {/* <div class="col-md-4">
                                        <div className="intel-hardwere-img">
                                            <img src='./images/Resellers-of-IT-Hardware.webp' />
                                        </div>
                                    </div> */}
                                </div>
                                <div class="row">
                                    <h2 className='key-f'>Key Components of Managed Services:</h2>
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con-h">
                                            <p><span>Network Management :</span> Monitoring and maintaining network performance and security.</p>
                                            <p><span>Data Backup and Recovery :</span> Regular backups of data and a plan to recover critical systems in case of a disaster, ensuring business continuity.</p>
                                            <p><span>Cloud Services :</span> Managing cloud infrastructure, including hosting, storage, and virtual servers.</p>
                                            <p><span>Security Management :</span> Implementing and managing security measures such as firewalls, antivirus software, and incident response to protect against cyber threats.</p>
                                            <p><span>Help Desk Support :</span> Providing technical support and troubleshooting for users.</p>
                                            <p><span>Software and Hardware Maintenance</span> Regular updates, patch management, and ensuring system reliability.</p>
                                            <p><span>Performance Optimization :</span> Regular assessments of system performance to identify and implement improvements for greater efficiency.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="submit-form">
                                <h3 className='msg-h'>Drop a Message..</h3>
                                <div class="formbold-main-wrapper">

                                    <div class="formbold-form-wrapper">
                                        <form >
                                            <div class="formbold-mb-5">
                                                <label for="name" class="formbold-form-label"> Full Name </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Full Name"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="email" class="formbold-form-label"> Email Address </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="subject" class="formbold-form-label"> Subject </label>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    placeholder="Enter your subject"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="message" class="formbold-form-label"> Message </label>
                                                <textarea
                                                    rows="6"
                                                    name="message"
                                                    id="message"
                                                    placeholder="Type your message"
                                                    class="formbold-form-input"
                                                ></textarea>
                                            </div>

                                            <div>
                                                <button class="formbold-btn">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intel-hardwere-img">
                        <img src='./images/managed-services-solutions.webp' />
                    </div>

                </Container>
            </section>
            <br /><br />
            <section id="clients" class="clients section">
                <div class="container section-title" data-aos="fade-up">
                    <h2>Our Hardware Partnerships</h2>
                    <br /><br />
                    <PartnerSider ClientsLogo={ClientsLogo} />
                </div>
            </section>
            <br /><br />
        </div>
    );
}

export default ManagedServices;