import React from 'react';

import Container from 'react-bootstrap/Container';
import Routespage from "./Routes"
function App() {
  return (
    <div >
    <Routespage/>
    </div>
  );
}

export default App;
