import React from 'react';

function SIMcard() {
    return (
        <div className='SIMcard'>
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/sim/e-sim.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                    eSIM & SIM solutions
                    </h1>
                    <p className='white-text' style={{color:"#fff"}}>SIM card manufacturing involves several stages, from designing the chip to embedding it into a plastic card. Here's a breakdown of the process:</p>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                      <div class="col-md-6 col-lg-6">
                            <div>
                                <img src='images/sim/eSIM-orchestration-architecture.png' />
                            </div>
                        </div>
                          <div class="col-md-6 col-lg-6">
                        <h2>eSIM management platform</h2>
                            <div>
                                <p>Connect new consumer and M2M IoT devices with the world’s first-ever cloud eSIM management solutions certified by GSMA’s Security Accreditation Scheme for Subscription Management. With a single, simplified, low cost, our secure, interoperable, scalable, and future-ready eSIM solutions can be quickly deployed to market so you can start selling eSIM in no time.</p>

                            </div>
                        </div>
                     
                    </div>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape  bg-blue-gr">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                        
                          <div class="col-md-6 col-lg-6">
                        <h2>eSIM activation</h2>
                            <div>
                                <p>eSIM provides telcos with a host of digital marketing and distribution opportunities. The digital nature of eSIM also means it can relieve heavy time, resources, and costs compared to managing the traditional SIM card. Workz offers a comprehensive range of activation solutions to enhance telcos’ activation rates.</p>
        

                            </div>
                        </div>
                          <div class="col-md-6 col-lg-6">
                            <div>
                                <img src='images/sim/esim.png' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                      <div class="col-md-6 col-lg-6">
                            <div>
                                <img src='images/sim/sim-card-png-picture.png' />
                            </div>
                        </div>
                          <div class="col-md-6 col-lg-6">
                        <h2>SIM card manufacturing</h2>
                            <div>
                                <p>As a global top 10 SIM provider, Workz offers a complete, integrated SIM solution. Our cost-efficient profile development, SIM production, technical support, data personalisation, packaging, and fulfilment all take place under one roof.</p>

                            </div>
                        </div>
                     
                    </div>
                </div>
            </section>
            <br/> <br/>
        </div>
    );
}

export default SIMcard;
