import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import './index.scss';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
export default function PartnerSider({ClientsLogo}) {
    return (
        <>
      
            <Swiper
                autoplay={{
                    delay: 1,
                    disableOnInteraction: false,
                }}
                speed={5000}
                loop={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay]}
                breakpoints={{
                    320: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 6,
                        spaceBetween: 20,
                    },
                }}

                className="mySwiper">
                {ClientsLogo.map((val, a) => {
                    return (
                        <SwiperSlide>
                            <div class="swiper-slide-img "><img src={val} class="img-fluid" alt="" /></div>
                        </SwiperSlide>
                    )
                })}

            </Swiper>



        </>
    )
}