import PartnerSider from "../components/partnerSider"
import "./ict.scss"
const ClientsLogo = [
    "./images/partner-img/microsoft.jpg",
    "./images/partner-img/redhat.png",
    "./images/partner-img/hp.png",
    "./images/partner-img/nutanix.png",
    "./images/partner-img/ubuntu.png",
    "./images/partner-img/lenovo.png",
    "./images/partner-img/checkpoint.png",
    "./images/partner-img/huawei.jpg",
  ]
  const ClientsLogo2 = [
    "./images/partner-img/microsoft.jpg",
    "./images/partner-img/veeam.png",
    "./images/partner-img/kaspersky.jpg",
    "./images/partner-img/vmware.jpg",
    "./images/partner-img/redhat.png",
    "./images/partner-img/fortinet.png",
    "./images/partner-img/mcafee.jpg",
    "./images/partner-img/office365.png",
    "./images/partner-img/checkpoint.png",
  ]
  const ClientsLogo3 = [
    "./images/partner-img/cisco.png",
    "./images/partner-img/sonicwall.png",
    "./images/partner-img/cyber.png",
    "./images/partner-img/forcepoint.png",
    "./images/partner-img/sophos.png",
    "./images/partner-img/juniper.png",
    "./images/partner-img/fortinet.png",
    "./images/partner-img/stormshield.png",
  ]
export default function ICTSolutions() {
    return (
        <div className="ICTSolutions">
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" style={{ background: `url('images/ict.jpg')no-repeat center center / cover` }}>
                <div class="container">

                    <div class="row">
                        <div class="col-md-7 col-lg-6">
                            <div className="text-back">
                                <div class="hero-slider-content">
                                    <h2>Intelcom Limited: A Comprehensive Company Profile</h2>
                                    <p class="lead">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                                        in the realm of Information and Communication Technologies (ICT) and
                                        Telecommunication equipment and solutions. The company offers a diverse range
                                        of ICT solutions and services, catering to the evolving needs of businesses and
                                        individuals across Tanzania. With its strong foundation and expertise, Intelcom has
                                        established itself as a trusted partner for various ICT and Telecommunication
                                        requirements</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br/><br/>
      <section id="clients" class="clients section">
        <div class="container section-title" data-aos="fade-up">
          <h2>Our Hardware Partnerships</h2>
       <br/><br/>
          <PartnerSider ClientsLogo={ClientsLogo} />
        </div>
      </section>
            <section class="customer-testimonial-section ptb-100 bg-left-shape">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-9 col-lg-8">
                            <div class="section-heading text-center mb-5">
                                <h2>Our Service</h2>
                                <p class="lead">We believe in treating clients with Respect &amp; Professionalism</p>  </div>
                        </div>
                    </div>

                    <div class="row align-items-center justify-content-between">
                            <div class="row">
                           
                                <div class="col-md-6">
                                    <div class="single-counter shadow-sm p-4 rounded text-center border">
                                        <img className="icon-img" src="./images/track-hardware-changes.png"/>
                                        <h5 class="mb-0"><a href="#">Resellers of IT Hardware and Softwares</a></h5>
                                        <p><a href="#">Resellers of IT Hardware and Softwares</a></p>
                                        <a className="more-btn" href="/resellers-of-IT-Hardware-and-Softwares">More</a>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="single-counter shadow-sm p-4 rounded text-center border">
                                    <img className="icon-img" src="./images/it-infrastructure-technical-support-managed-services.png"/>
                                    
                                        <h5 class="mb-0"><a href="#">Cuber security solutions</a></h5>
                                        <p><a href="#">Property buy, sell, retal, maintenance work orders &amp; listing website.  </a></p>
                                        <a className="more-btn" href="/cuber-security-solutions">More</a>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="single-counter shadow-sm p-4 rounded text-center border">
                                    <img className="icon-img" src="./images/cyber-security.png"/>
                                        <h5 class="mb-0"><a href="#">Managed Services</a></h5>
                                        <p><a href="#">Complete job search engine with employee, employer &amp; admin panel.</a></p>
                                        <a className="more-btn" href="/managed-services">More</a>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="single-counter shadow-sm p-4 rounded text-center border">
                                    <img className="icon-img" src="./images/erp.png"/>
                                        <h5 class="mb-0"><a href="#">Enterprise Resource Planning (ERP)</a></h5>
                                        <p><a href="#">Matrimony business logics and stunning UI development with 3rd party API. </a></p>
                                        <a className="more-btn" href="/enterprise-resource-planning">More</a>
                                    </div>
                                </div>

                            </div>
                        </div>
                </div>
            </section>
            <br/><br/>
      <section id="clients" class="clients section">
        <div class="container section-title" data-aos="fade-up">
          <h2>Cyber Security Solutions Partnerships</h2>
       <br/><br/>
          <PartnerSider ClientsLogo={ClientsLogo2} />
        </div>
        </section>
        <br/><br/>
        </div>
    )
}