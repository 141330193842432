import React from 'react';
import Slider from '../components/slider';
import Card from '../components/card';
import "./home.scss"
import PartnerSider from '../components/partnerSider';
const ClientsLogo = [
  "./images/partner-img/microsoft.jpg",
  "./images/partner-img/redhat.png",
  "./images/partner-img/hp.png",
  "./images/partner-img/nutanix.png",
  "./images/partner-img/ubuntu.png",
  "./images/partner-img/lenovo.png",
  "./images/partner-img/checkpoint.png",
  "./images/partner-img/huawei.jpg",
]
const ClientsLogo2 = [
  "./images/partner-img/microsoft.jpg",
  "./images/partner-img/veeam.png",
  "./images/partner-img/kaspersky.jpg",
  "./images/partner-img/vmware.jpg",
  "./images/partner-img/redhat.png",
  "./images/partner-img/fortinet.png",
  "./images/partner-img/mcafee.jpg",
  "./images/partner-img/office365.png",
  "./images/partner-img/checkpoint.png",
]
const ClientsLogo3 = [
  "./images/partner-img/cisco.png",
  "./images/partner-img/sonicwall.png",
  "./images/partner-img/cyber.png",
  "./images/partner-img/forcepoint.png",
  "./images/partner-img/sophos.png",
  "./images/partner-img/juniper.png",
  "./images/partner-img/fortinet.png",
  "./images/partner-img/stormshield.png",
]
function Home() {
  return (
    <div>
      <Slider />
      <Card /><br/><br/>
      <section id="clients" class="clients section">
        <div class="container section-title" data-aos="fade-up">
          <h2>Our Hardware Partnerships</h2>
       <br/><br/>
          <PartnerSider ClientsLogo={ClientsLogo} />
        </div>
      </section>
      <section class="customer-testimonial-section ptb-100 bg-left-shape">
        <div class="container">
          <div class="row align-items-center justify-content-between">
            <div class="col-md-5 col-lg-5">
              <div class="row">
                <div class="col-md-12">
                  <div class="single-counter shadow-sm p-4 rounded text-center border">
                    <span class="ti-medall-alt color-secondary icon-md"></span>
                    <h4 class="mb-0">Our Vision</h4>
                    <p>To be the trusted partner for businesses across
                      Africa, delivering exceptional ICT and
                      Telecommunication solutions that drive growth and
                      success.</p>

                  </div>
                </div>
                <div class="col-md-12">
                  <div class="single-counter shadow-sm p-4 rounded text-center border">
                    <span class="ti-cup color-secondary icon-md"></span>
                    <h4 class="mb-0">Our mission</h4>
                    <p>To create mutually beneficial partnerships that drive
                      innovation and growth for our vendors and business
                      partners.</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="single-counter shadow-sm p-4 rounded text-center border">
                    <span class="ti-user color-secondary icon-md"></span>
                    <h4 class="mb-0">Industry Specific</h4>
                    <p>With years of experience and multiple domain expertise - We have industry specific solutions &amp; systems</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="about-img-wrap">
                <img src="images/about-us.jpg" alt="video" class="img-fluid rounded shadow-sm" />

              </div>
            </div>

          </div>
        </div>
      </section>
     
      <section class="call-to-action py-5 ">
        <div class="container">
          <div class="row justify-content-around align-items-center">
            <div class="col-md-9">
              <div class="subscribe-content">
                <h3 class="mb-1">What Exactly We Do Here</h3>
                {/* <p>At webkype, we create pixel-perfect websites, CRM and applications! from initial planning to going live and beyond; we do all the heavy-lifting to make your online presence a big reality!</p> */}
              </div>
            </div>
            <div class="col-md-3">
              <div class="action-btn text-lg-right text-sm-left">
                <a href="#" class="btn secondary-solid-btn">View All</a>
              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="core-services-single rounded p-5 my-md-3 my-lg-3 my-sm-0 shadow-lg">
                <span class="fas fa-ad icon-md mb-3 d-block color-primary"></span>
                <h5>ITC Services & ERP</h5>
                <p class="mb-0">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                  in the realm of Information and Communication Technologies </p>
                <a href="/ictsolution" class="icon-link secondary-bg"><span class="ti-angle-double-right"></span></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="core-services-single rounded p-5 my-md-3 my-lg-3 my-sm-0 shadow-lg">
                <span class="fas fa-briefcase icon-md mb-3 d-block color-primary"></span>
                <h5>SIM Services</h5>
                <p class="mb-0">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                  in the realm of Information and Communication Technologies </p>
                <a href="#" class="icon-link secondary-bg"><span class="ti-angle-double-right"></span></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="core-services-single rounded p-5 my-md-3 my-lg-3 my-sm-0 shadow-lg">
                <span class="fas fa-lightbulb icon-md mb-3 d-block color-primary"></span>
                <h5>Scratch card Services</h5>
                <p class="mb-0">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                  in the realm of Information and Communication Technologies </p>
                <a href="#" class="icon-link secondary-bg"><span class="ti-angle-double-right"></span></a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 col-md-4">
              <div class="core-services-single rounded p-5 my-md-3 my-lg-3 my-sm-0 shadow-lg">
                <span class="fas fa-ad icon-md mb-3 d-block color-primary"></span>
                <h5>Resellers of IT Hardware and Softwares</h5>
                <p class="mb-0">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                  in the realm of Information and Communication Technologies </p>
                <a href="/cuber-security-solutions" class="icon-link secondary-bg"><span class="ti-angle-double-right"></span></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="core-services-single rounded p-5 my-md-3 my-lg-3 my-sm-0 shadow-lg">
                <span class="fas fa-briefcase icon-md mb-3 d-block color-primary"></span>
                <h5>Cuber security solutions</h5>
                <p class="mb-0">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                  in the realm of Information and Communication Technologies </p>
                <a href="#" class="icon-link secondary-bg"><span class="ti-angle-double-right"></span></a>
              </div>
            </div>
            <div class="col-lg-4 col-md-4">
              <div class="core-services-single rounded p-5 my-md-3 my-lg-3 my-sm-0 shadow-lg">
                <span class="fas fa-lightbulb icon-md mb-3 d-block color-primary"></span>
                <h5>Managed services solutions</h5>
                <p class="mb-0">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                  in the realm of Information and Communication Technologies </p>
                <a href="/managed-services" class="icon-link secondary-bg"><span class="ti-angle-double-right"></span></a>
              </div>
            </div>
          </div>
     
        </div>
      </section>
      <br/><br/>
      <section id="clients" class="clients section">
        <div class="container section-title" data-aos="fade-up">
          <h2>Our Software Partnerships</h2>
       <br/><br/>
          <PartnerSider ClientsLogo={ClientsLogo2} />
        </div>
      </section><br/><br/>
      <section class="promo-section gradient-bg ptb-100" style={{ padding: "40px 0" }}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-10 col-lg-10">
              <div class="app-business-content text-center text-white">
                <h1 class="text-white">Since 2020</h1>
                <p class="lead">Intelcom Limited, headquartered in Dar es Salaam, Tanzania, is a prominent player
                  in the realm of Information and Communication Technologies (ICT) and
                  Telecommunication equipment and solutions. The company offers a diverse range
                  of ICT solutions and services, catering to the evolving needs of businesses and
                  individuals across Tanzania. With its strong foundation and expertise, Intelcom has
                  established itself as a trusted partner for various ICT and Telecommunication
                  requirements.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section class="customer-testimonial-section ptb-100 bg-left-shape">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-8 col-lg-8">
              <div class="section-heading text-center mb-4">
                <h2>We are Growing - <a href="about.html"> About us</a></h2>
                <p class="lead">We have earned the experience and knowledge to get your vision comes true via Continuous search for opportunities beyond the agreed communications and business objectives.</p>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-between">
            <div class="col-md-5 col-lg-5">
              <div class="row">
                <div class="col-md-6">
                  <div class="single-counter shadow-sm p-4 rounded text-center border">
                    <span class="ti-medall-alt color-secondary icon-md"></span>
                    <h3 class="mb-0">11</h3>
                    <p>Years in Business</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="single-counter shadow-sm p-4 rounded text-center border">
                    <span class="ti-headphone-alt color-secondary icon-md"></span>
                    <h3 class="mb-0">10</h3>
                    <p>Office</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="single-counter shadow-sm p-4 rounded text-center border">
                    <span class="ti-cup color-secondary icon-md"></span>
                    <h3 class="mb-0">5075</h3>
                    <p>Global client</p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="single-counter shadow-sm p-4 rounded text-center border">
                    <span class="ti-user color-secondary icon-md"></span>
                    <h3 class="mb-0">30</h3>
                    <p>Professionals</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <div class="image-wrap">
                <img src="./images/about-img.jpg" alt="video" class="img-fluid rounded" />
              </div>
            </div>

          </div>
        </div>
      </section>
      <br/><br/>
      <section id="clients" class="clients section">
        <div class="container section-title" data-aos="fade-up">
          <h2>Cyber Security Solutions
          Partnerships</h2>
       <br/><br/>
          <PartnerSider ClientsLogo={ClientsLogo3} />
        </div>
      </section><br/><br/>
    </div>
  );
}

export default Home;
