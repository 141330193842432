import React from 'react';

function Scratchcard() {
    return (
        <div className='Scratchcard'>
            
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/card/s-card.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                    Recharge card manufacturing
                    </h1>
                    <p className='white-text' style={{color:"#fff"}}>As the world’s number one recharge card manufacturer, we print the most advanced, secure and cost-effective pre-paid phone cards available with innovative value-added features to ensure the best recharge experience for mobile subscribers.</p>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                    <div class="col-md-6 col-lg-6">
                            <div className='card-scrch'>
                                <img src='images/card/card-02.png' />
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                        <h2>Switch to the No.1 prepaid card</h2>
                            <div>
                                <p>Workz Macwhite 2.0 is a technological breakthrough in recharge card performance. With proven success in over 70 of the most demanding markets in the world, end-users enjoy a rewarding, hassle-free recharge experience every time.</p>

                            </div>
                            <ul class="simple-list- free-ass">
                            <li class="list-item-"><img src="images/check.png" alt=""/> Detailed evaluation of your current workloads.</li>
                            <li class="list-item-"><img src="images/check.png" alt=""/> Reinforced over-scratch protection to significantly reduce market complaints</li>
                            <li class="list-item-"><img src="images/check.png" alt=""/> Razor-sharp PIN definition for optimal use</li>
                            <li class="list-item-"><img src="images/check.png" alt=""/> Impenetrable tamper-proof panel to protect against fraud</li>
                            <li class="list-item-"><img src="images/check.png" alt=""/> Sleeker, lighter and more durable to reduce freight costs.</li>
                            </ul>
                        </div>
                     
                    </div>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape  bg-blue-gr">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                        
                          <div class="col-md-6 col-lg-6">
                        <h2>eSIM activation</h2>
                            <div>
                                <p>eSIM provides telcos with a host of digital marketing and distribution opportunities. The digital nature of eSIM also means it can relieve heavy time, resources, and costs compared to managing the traditional SIM card. Workz offers a comprehensive range of activation solutions to enhance telcos’ activation rates.</p>
        

                            </div>
                        </div>
                          <div class="col-md-6 col-lg-6">
                        <div className='card-scrch'>
                                <img src='images/card/card-sim01.png' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <br/> <br/>
            <section class="customer-sim-section ptb-10 bg-left-shape">
                <div class="container">

                    <div class="row align-items-center justify-content-between">
                      <div class="col-md-6 col-lg-6">
                    <div className='card-scrch'>
                                <img src='images/card/s-card.jpg' />
                            </div>
                        </div>
                          <div class="col-md-6 col-lg-6">
                        <h2>SIM card manufacturing</h2>
                            <div>
                                <p>As a global top 10 SIM provider, Workz offers a complete, integrated SIM solution. Our cost-efficient profile development, SIM production, technical support, data personalisation, packaging, and fulfilment all take place under one roof.</p>

                            </div>
                        </div>
                     
                    </div>
                </div>
            </section>
            <br/> <br/>
        </div>
    );
}

export default Scratchcard;
