
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import PartnerSider from '../partnerSider';
const ClientsLogo = [
 "./images/partner-img/microsoft.jpg",
  "./images/partner-img/veeam.png",
  "./images/partner-img/kaspersky.jpg",
  "./images/partner-img/vmware.jpg",
  "./images/partner-img/redhat.png",
  "./images/partner-img/fortinet.png",
  "./images/partner-img/mcafee.jpg",
  "./images/partner-img/office365.png",
  "./images/partner-img/checkpoint.png",
]
function ERP() {
    return (
        <div className='HardwareandSoftwares'>
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/ict.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                    Enterprise Resource Planning (ERP)
                    </h1>
                </div>
            </section>
            <br /> <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" >

                <Container >
                    <div class="row">
                        <div class="col-md-8">
                            <div className="intel-hardwere">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con">
                                            <p>Enterprise Resource Planning (ERP) systems are integrated software solutions that help organizations manage their business processes in a unified way. These systems bring together various core functions, such as finance, supply chain, human resources, and customer relationship management, into one platform to improve efficiency, decision-making, and productivity.</p>
                                        </div>
                                    </div>
                                    {/* <div class="col-md-4">
                                        <div className="intel-hardwere-img">
                                            <img src='./images/Resellers-of-IT-Hardware.webp' />
                                        </div>
                                    </div> */}
                                </div>
                                <div class="row">
                                    <h2 className='key-f'>Key Modules of ERP:</h2>
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con-h">
                                            <p><span>Finance and Accounting :</span> Automates financial processes like accounts payable, accounts receivable, general ledger, and financial reporting.</p>
                                            <p><span>Supply Chain Management (SCM) :</span> Manages procurement, inventory, production, and logistics to ensure efficient flow of goods and materials.</p>
                                            <p><span>Human Resource Management (HRM) :</span> Handles employee data, payroll, recruitment, performance management, and benefits.</p>
                                            <p><span>Customer Relationship Management (CRM) :</span>  Manages interactions with customers, tracks sales, and supports marketing efforts.</p>
                                            <p><span>Manufacturing :</span> Supports production planning, tracking, and control to improve manufacturing efficiency.</p>
                                            <p><span>Sales and Marketing :</span> Tracks sales orders, manages customer interactions, and provides data to drive marketing strategies.</p>
                                            <p><span>Inventory Management :</span> Manages stock levels, orders, and warehouse activities to ensure optimal inventory levels.</p>
                                            <p><span>Project Managemen :</span> Manages project schedules, resources, and budgets.</p>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="submit-form">
                                <h3 className='msg-h'>Drop a Message..</h3>
                                <div class="formbold-main-wrapper">

                                    <div class="formbold-form-wrapper">
                                        <form >
                                            <div class="formbold-mb-5">
                                                <label for="name" class="formbold-form-label"> Full Name </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Full Name"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="email" class="formbold-form-label"> Email Address </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="subject" class="formbold-form-label"> Subject </label>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    placeholder="Enter your subject"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="message" class="formbold-form-label"> Message </label>
                                                <textarea
                                                    rows="6"
                                                    name="message"
                                                    id="message"
                                                    placeholder="Type your message"
                                                    class="formbold-form-input"
                                                ></textarea>
                                            </div>

                                            <div>
                                                <button class="formbold-btn">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intel-hardwere-img">
                        <img src='./images/erp.webp' />
                    </div>

                </Container>
            </section>
            <br /><br />
            <section id="clients" class="clients section">
                <div class="container section-title" data-aos="fade-up">
                    <h2>Our Software Partnerships</h2>
                    <br /><br />
                    <PartnerSider ClientsLogo={ClientsLogo} />
                </div>
            </section>
            <br /><br />
        </div>
    );
}

export default ERP;