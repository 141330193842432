
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import PartnerSider from '../partnerSider';
const ClientsLogo = [
    "./images/partner-img/cisco.png",
  "./images/partner-img/sonicwall.png",
  "./images/partner-img/cyber.png",
  "./images/partner-img/forcepoint.png",
  "./images/partner-img/sophos.png",
  "./images/partner-img/juniper.png",
  "./images/partner-img/fortinet.png",
  "./images/partner-img/stormshield.png",
]
function Cubersecuritysolutions() {
    return (
        <div className='HardwareandSoftwares'>
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/ict.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                        Cuber security solutions
                    </h1>
                </div>
            </section>
            <br /> <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" >

                <Container >
                    <div class="row">
                        <div class="col-md-8">
                            <div className="intel-hardwere">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con">
                                            <p>Cybersecurity solutions are essential for protecting organizations and individuals from various cyber threats, including data breaches, malware, and unauthorized access. These solutions encompass a broad range of technologies and practices designed to safeguard information systems, networks, and data.</p>
                                        </div>
                                    </div>
                                    {/* <div class="col-md-4">
                                        <div className="intel-hardwere-img">
                                            <img src='./images/Resellers-of-IT-Hardware.webp' />
                                        </div>
                                    </div> */}
                                </div>
                                <div class="row">
                                    <h2 className='key-f'>Key Elements of Cybersecurity Solutions:</h2>
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con-h">
                                            <p><span>Firewalls :</span> These act as barriers between trusted and untrusted networks, filtering incoming and outgoing traffic based on predefined security rules.</p>
                                            <p><span>Antivirus and Anti-Malware :</span> Software that detects and removes malicious programs designed to harm or exploit systems.</p>
                                            <p><span>Encryption :</span> Data is encrypted to protect sensitive information from being accessed by unauthorized users during transmission or storage.</p>
                                            <p><span>Intrusion Detection and Prevention Systems (IDPS) :</span> These systems monitor network traffic for suspicious activities and provide real-time alerts or take action to prevent attacks.</p>
                                            <p><span>Identity and Access Management (IAM) :</span> Ensures that only authorized users can access certain resources by implementing strong authentication, like multi-factor authentication (MFA).</p>
                                            <p><span>Security Information and Event Management (SIEM) :</span> Tools that provide real-time analysis of security alerts generated by applications and network hardware.</p>
                                            <p><span>Endpoint Security :</span> Protects devices like laptops, smartphones, and tablets from threats that target endpoints.</p>
                                            <p><span>Cloud Security :</span> Protects data, applications, and infrastructures in the cloud by employing access controls, encryption, and secure configurations.</p>
                                            <p><span>Network Security :</span> Secures the infrastructure by implementing measures like segmentation, monitoring, and traffic control.</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="submit-form">
                                <h3 className='msg-h'>Drop a Message..</h3>
                                <div class="formbold-main-wrapper">

                                    <div class="formbold-form-wrapper">
                                        <form >
                                            <div class="formbold-mb-5">
                                                <label for="name" class="formbold-form-label"> Full Name </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Full Name"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="email" class="formbold-form-label"> Email Address </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="subject" class="formbold-form-label"> Subject </label>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    placeholder="Enter your subject"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="message" class="formbold-form-label"> Message </label>
                                                <textarea
                                                    rows="6"
                                                    name="message"
                                                    id="message"
                                                    placeholder="Type your message"
                                                    class="formbold-form-input"
                                                ></textarea>
                                            </div>

                                            <div>
                                                <button class="formbold-btn">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intel-hardwere-img">
                        <img src='./images/cyber-security-page.webp' />
                    </div>

                </Container>
            </section>
            <br /><br />
            <section id="clients" class="clients section">
                <div class="container section-title" data-aos="fade-up">
                    <h2>Cyber Security Solutions Partnerships</h2>
                    <br /><br />
                    <PartnerSider ClientsLogo={ClientsLogo} />
                </div>
            </section>
            <br /><br />
        </div>
    );
}

export default Cubersecuritysolutions;