import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service';
import Contact from './pages/Contact';
import Product from './pages/Product';
import Header from './components/header';
import Footer from './components/footer';
import ICTSolutions from './pages/ICTSolutions';
import HardwareandSoftwares from './components/HardwareandSoftwares';
import Cubersecuritysolutions from './components/Cubersecuritysolutions';
import ManagedServices from './components/ManagedServices';
import ERP from './components/ERP';
import SIMcard from './pages/SIMcard';
import Scratchcard from './pages/Scratchcard';

function Routespage() {
  return (
    <Router>
      <div className='intelcom'>
      
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/product" element={<Product />} />
          <Route path="/sim" element={<SIMcard />} />
          <Route path="/scratchcard" element={<Scratchcard/>} />
          <Route path="/ictsolution" element={<ICTSolutions />} />
          <Route path="/resellers-of-IT-Hardware-and-Softwares" element={<HardwareandSoftwares />} />
          <Route path="/cuber-security-solutions" element={<Cubersecuritysolutions />} />
          <Route path="/managed-services" element={<ManagedServices />} />
          <Route path="/enterprise-resource-planning" element={<ERP />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default Routespage;
