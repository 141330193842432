import React from 'react';

function Product() {
  return (
    <div>
      <h1>Product Page</h1>
      <p>Here are the products we offer.</p>
    </div>
  );
}

export default Product;
