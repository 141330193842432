
import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import PartnerSider from '../partnerSider';
const ClientsLogo = [
    "./images/partner-img/microsoft.jpg",
    "./images/partner-img/redhat.png",
    "./images/partner-img/hp.png",
    "./images/partner-img/nutanix.png",
    "./images/partner-img/ubuntu.png",
    "./images/partner-img/lenovo.png",
    "./images/partner-img/checkpoint.png",
    "./images/partner-img/huawei.jpg",
]
function HardwareandSoftwares() {
    return (
        <div className='HardwareandSoftwares'>
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light text-center" style={{ background: `url('images/ict.jpg')no-repeat center center / cover` }}>
                <div className='heading-tital'>
                    <h1>
                        Resellers of IT Hardware and Softwares
                    </h1>
                </div>
            </section>
            <br /> <br />
            <section class="hero-equal-height pt-165 pb-100 gradient-overly-right-light" >

                <Container >
                    <div class="row">
                        <div class="col-md-8">
                            <div className="intel-hardwere">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con">
                                            <p>
                                                Resellers of IT hardware and software play a crucial role in the technology supply chain by purchasing products from manufacturers and distributors and selling them to businesses or end-users. These resellers can specialize in different categories, including enterprise solutions, consumer products, or niche markets like gaming or cybersecurity.</p>
                                        </div>
                                    </div>
                                    {/* <div class="col-md-4">
                                        <div className="intel-hardwere-img">
                                            <img src='./images/Resellers-of-IT-Hardware.webp' />
                                        </div>
                                    </div> */}
                                </div>
                                <div class="row">
                                    <h2 className='key-f'>Key Functions of IT Hardware and Software Resellers:</h2>
                                    <div class="col-md-12">
                                        <div className="intel-hardwere-con-h">
                                            <p><span>Product Sourcing :</span> Resellers procure products such as servers, storage devices, computers, networking equipment, or software licenses from vendors.</p>
                                            <p><span>Value-Added Services :</span> Many resellers provide additional services like installation, configuration, maintenance, or custom solutions tailored to the needs of their clients. These can include managed services, cloud solutions, and consulting.</p>
                                            <p><span>Distribution :</span> Resellers often have partnerships with key brands (e.g., Dell, HP, Microsoft, Cisco) and offer these products to a variety of markets. They serve small to medium-sized businesses, large enterprises, and public sector clients.</p>
                                            <p><span>Customer Support :</span> Resellers provide pre-sales consultation and post-sales technical support, which is critical for clients who rely on technology to run their businesses smoothly.</p>
                                            <p><span>Licensing and Compliance :</span> Resellers help businesses manage software licensing, ensuring compliance with vendor regulations and optimizing license costs.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div className="submit-form">
                                <h3 className='msg-h'>Drop a Message..</h3>
                                <div class="formbold-main-wrapper">

                                    <div class="formbold-form-wrapper">
                                        <form >
                                            <div class="formbold-mb-5">
                                                <label for="name" class="formbold-form-label"> Full Name </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    placeholder="Full Name"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="email" class="formbold-form-label"> Email Address </label>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Enter your email"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="subject" class="formbold-form-label"> Subject </label>
                                                <input
                                                    type="text"
                                                    name="subject"
                                                    id="subject"
                                                    placeholder="Enter your subject"
                                                    class="formbold-form-input"
                                                />
                                            </div>

                                            <div class="formbold-mb-5">
                                                <label for="message" class="formbold-form-label"> Message </label>
                                                <textarea
                                                    rows="6"
                                                    name="message"
                                                    id="message"
                                                    placeholder="Type your message"
                                                    class="formbold-form-input"
                                                ></textarea>
                                            </div>

                                            <div>
                                                <button class="formbold-btn">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intel-hardwere-img">
                        <img src='./images/Resellers-of-IT-Hardware.webp' />
                    </div>

                </Container>
            </section>
            <br/><br/>
            <section id="clients" class="clients section">
                <div class="container section-title" data-aos="fade-up">
                    <h2>Our Hardware Partnerships</h2>
                    <br /><br />
                    <PartnerSider ClientsLogo={ClientsLogo} />
                </div>
            </section>
            <br/><br/>
        </div>
    );
}

export default HardwareandSoftwares;